export const topCurrencyNames = [
  "Australian dollars",
  "Brazilian reals",
  "British pounds sterling",
  "Canadian dollars",
  "Chilean pesos",
  "Denmark kroner",
  "Euros",
  "Hungarian forints",
  "Indian rupees",
  "Japanese yen",
  "New Zealand dollars",
  "Norwegian kroner",
  "Peruvian nuevos soles",
  "Polish zlotys",
  "Russian rubles",
  "South African Rand",
  "Swedish kronor",
  "Swiss francs",
  "US dollars",
]