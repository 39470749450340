const casinoTypesForVerticals = [
  {
    id: 'for_vertical_slots',
    name: 'Slots',
  },
  {
    id: 'for_vertical_ios',
    name: 'iPhone App',
  },
  {
    id: 'for_vertical_android',
    name: 'Android App',
  },
  {
    id: 'for_vertical_mobile_browser',
    name: 'Mobile browser',
  },
  {
    id: 'for_vertical_sportsbook',
    name: 'Sportsbook',
  },
  {
    id: 'for_vertical_live_dealer',
    name: 'Live Dealer',
  },
  {
    id: 'for_vertical_lottery',
    name: 'Lottery',
  },
  {
    id: 'for_vertical_crypto',
    name: 'Crypto casino',
  },
];

export const addCasinoTypesForVertical = (casinoTypes) => {
  // Extract names from existing casinoTypes
  const existingNames = new Set(casinoTypes.map((type) => type.name));

  // Filter and add missing items from casinoTypesForVerticals
  const updatedCasinoTypes = [
    ...casinoTypes,
    ...casinoTypesForVerticals.filter((type) => !existingNames.has(type.name)),
  ];

  // Sort alphabetically by name
  return updatedCasinoTypes.sort((a, b) => a.name.localeCompare(b.name));
}