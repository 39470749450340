import React from 'react'

import './index.scss'

const SectionContainer = ({
  title,
  desc,
  overrideStyling,
  singleColumn = false,
  ...props
}) => {
  const widthProps = overrideStyling ? '50%' : ''
  const containerProps = overrideStyling ? '' : 'sub-container'

  return (
    <div className={`${containerProps}`} style={{ width: `${widthProps}` }}>
      {!overrideStyling ? (
        <div>
          <h3 style={{ display: 'inline' }}>{title}</h3>
          <br />
          <span
            style={{ fontSize: '0.75rem', opacity: '0.45', margin: '10px 0' }}
            className="description-text">
            {desc}
          </span>
          <br />{' '}
        </div>
      ) : null}

      <div className={singleColumn ? 'checkbox-group-single-col' : 'checkbox-group'}>
        {props.children}
      </div>
    </div>
  )
}

export default SectionContainer;
