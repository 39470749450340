export const topCountryNames = [
  "Australia",
  "Austria",
  "Brazil",
  "Canada",
  "Chile",
  "Czechia",
  "Denmark",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "India",
  "Ireland",
  "Italy",
  "Japan",
  "Latvia",
  "New Zealand",
  "Norway",
  "Peru",
  "Poland",
  "Portugal",
  "Slovakia",
  "South Korea",
  "Sweden",
  "Switzerland",
  "Turkey",
  "United Kingdom"
]