export const topDepositMethodNames = [
  "Visa",
  "MasterCard",
  "Neteller",
  "Skrill",
  "PaysafeCard",
  "Trustly",
  "Interac",
  "PayPal",
  "Maestro",
  "Payz",
  "Sofort",
  "MuchBetter",
  "Bank Transfer",
  "Credit Cards",
  "Neosurf",
  "Bancolombia",
  "AstroPay",
  "inviPay",
  "GiroPay",
  "Zimpler",
  "Euteller",
  "Jeton",
  "Crypto",
  "Bitcoin",
  "Apple Pay",
  "Rapid Transfer",
  "EPS",
  "Visa Electron",
  "Skrill 1-Ta",
];

export const disabledDepositMethodNames = [
  "Bank Transfer",
  "Credit Cards",
  "E-wallets",
  "Pay by Mobile",
  "Prepaid Cards",
  "Wire Transfer",
  "Crypto",
]