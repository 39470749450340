import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptcha({ onChange }) {
  const handleChange = async (key) => {
    onChange(key);
  };

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      onChange={handleChange}
    />
  );
}
