import React from "react";
import ProviderForm from "./application-form/ApplicationForm";

import "./ProviderApplication.scss";

export default function ProviderApplication() {
  return (
    <div id="main-container">
      <h1>Submit your Casino for review to get listed</h1>
      <p style={{ marginBottom: "16px" }}>
        Enter and submit your brand's details in the form below, our team will
        review your application and make sure that you meet the criterias.
      </p>

      <ProviderForm></ProviderForm>
    </div>
  );
}
