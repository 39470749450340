export const affiliateSystems = [
  "1xBitPartners",
  "1xPartners",
  "1xSlotPartners",
  "7K Partners",
  "888",
  "888Starz Partners",
  "Affbritish",
  "Affiliate Control",
  "Affiliatly",
  "Affise",
  "Affmore",
  "Alanbase",
  "Awin",
  "Bet And You Partners",
  "Bet365",
  "BetConstruct",
  "BetFlag.it",
  "Bettilt Partners",
  "Betway Partners Africa",
  "Betway.partners",
  "Bitsler Partners",
  "Bona Fides Affiliates",
  "Brazpartners",
  "Buffalo Partners",
  "Cake",
  "Cellxpert",
  "Cloudbet",
  "Condor Affiliates",
  "Confido Network",
  "Deckmedia",
  "Drake Affiliates",
  "DrBet",
  "Dream.stream",
  "Egass",
  "EGO",
  "Esportiva Bet Affiliates",
  "Everflow",
  "EveryMatrix",
  "Finance Ads",
  "Gambler Hunt Affiliates",
  "Gambling Affiliation",
  "GamblingCraft",
  "GenerationWeb",
  "Gofiliate",
  "GrandX Affiliates",
  "GVC Affiliates",
  "HasOffers",
  "Income Access",
  "Income Partners",
  "Intertops",
  "JeetWin",
  "Jimpartners",
  "Jumpman Affiliates",
  "Jumpman Partners",
  "Jungleraja",
  "JVSpin",
  "LinkConnector",
  "LivePartners",
  "MAP",
  "Matching Visions",
  "Medterra Affiliates",
  "Megapari",
  "Melbet",
  "Mexos",
  "Mostbet",
  "MrBet",
  "MyAffiliates",
  "MyLotto.com",
  "Netrefer",
  "Number1Affiliates",
  "Omarsys",
  "One Affiliate Club",
  "Online Casino Share",
  "Onyx Affiliates",
  "ParipesaPartners",
  "PepperJam",
  "Pin-Up Partners",
  "Playattack",
  "Plus500 Affiliates",
  "Poshfriends",
  "PostAffiliatePro",
  "Prime Partners",
  "Quints.io",
  "Raven",
  "ReferOn",
  "RevenueLab",
  "Rewards Affiliates",
  "RTG",
  "Sarah Enterprises v2",
  "Shareasale",
  "Shark Partners",
  "Silverplay Affiliates",
  "Slotsvendor",
  "Smartico",
  "SoftGamings",
  "SoftSwiss",
  "SpreadEx",
  "Starvegas",
  "Tipbet",
  "Total Affiliates",
  "Traffic Manager",
  "Traffikongs.com",
  "Ultra partners",
  "v.partners",
  "VJ Japan",
  "Voonix",
  "Wazobet",
  "Winning Partner",
  "WinWin Partners",
  "Wynta",
]