import "antd/dist/antd.css";
import { Button, Input } from 'antd'
import { useState } from 'react'
import './index.scss';

const { TextArea } = Input;

export default function BulkInsert({
  items,
  selectItem,
  unselectItem,
  itemEqualsString
}) {
  const [taxonomyListStr, setTaxonomyListStr] = useState('');
  const [statusStr, setStatusStr] = useState('');
  const [status, setStatus] = useState(true);

  function parseListStr(str) {
    const separators = [',', ';', '|', '\t', '\n'];
    const separatorCount = {};

    separators.forEach(sep => {
      separatorCount[sep] = (str.match(new RegExp(`\\${sep}`, 'g')) || []).length;
    });

    const mostFrequentSeparator = Object.keys(separatorCount).reduce((a, b) =>
      separatorCount[a] > separatorCount[b] ? a : b
    );

    return new Set(str.split(mostFrequentSeparator).map(entry => entry.trim().toLowerCase()).filter(str => str.length));
  }

  const updateStats = (processedNames, notProcessedNames) => {
    const processedNum = processedNames.length;
    const notProcessedNum = notProcessedNames.length;
    if (notProcessedNames.length) {
      setStatus(false);
      setStatusStr([
        `Processed ${processedNum} / ${notProcessedNum + processedNum}`, <br/>,
        `Failed to process: ${notProcessedNames.map(s=>`"${s}"`).join(', ')}`]);
    } else {
      setStatus(true);
      setStatusStr(`Processed ${processedNum} item(s)`);
    }
  }

  const parseFindProcess = (process) => {
    const namesToProcess = parseListStr(taxonomyListStr);
    const notProcessedNames = [];
    const processedNames = [];

    for (let name of namesToProcess) {
      const i = items.find(i => itemEqualsString(i, name));
      if (i) {
        if (process(i))
          processedNames.push(name);
        else
          notProcessedNames.push(name);
      } else {
        notProcessedNames.push(name);
      }
    }

    updateStats(processedNames, notProcessedNames);
  }

  const handleTaxonomyListStrChange = (event) => {
    setStatusStr(' ');
    setTaxonomyListStr(event.target.value.trim());
  };

  const handleInsertAll = () => {
    parseFindProcess(selectItem);
  }

  const handleRemoveAll = () => {
    parseFindProcess(unselectItem);
  }

  return (
    <div className="bulk-insert-container">
      <TextArea
        placeholder="To insert in bulk, input a list of names separated by , | ; tab or new line"
        autoSize={{ minRows: 2, maxRows: 6 }}
        className="bulkInsertInput"
        onChange={handleTaxonomyListStrChange}
      />
      <div className="buli">
        <Button
          onClick={handleInsertAll}
          className="bulk-insert-btn"
        >
          Insert All
        </Button>
        <Button
          onClick={handleRemoveAll}
          className="bulk-insert-btn"
        >
          Remove
        </Button>
        <div style={{
          marginTop: '10px',
          height: '2rem',
          color: status ? 'green' : 'red'}}>
          {statusStr}
        </div>
      </div>
    </div>
  );
}

