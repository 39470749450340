import React from "react";
import { Link } from "react-router-dom";

import "./Header.scss";

export default function Header() {
  return (
    <div id="header">
      <header>
        <Link to="/">
          <img
            alt="OnlineCasinoRank.se"
            src="https://onlinecasinorank.se/assets/onlinecasinorank-se_logo?transformations=f_auto,c_fill,q_auto,fl_progressive"
          ></img>
        </Link>
      </header>
    </div>
  );
}
