import React from "react";
import { Link } from "react-router-dom";

import "./Home.scss";

export default function Home() {
  return (
    <div id="wrapper">
      <div id="home-container" style={{ height: "60%", marginTop: "40px" }}>
        <h1>List your Casino on CasinoRank</h1>
        <p>
          Submit your brand for review to become listed with{" "}
          <span>the best casinos in the world</span>. Having your casino listed
          here will highlight information about your casino of your choosing.
          Listing your casino on CasinoRank today is the best step towards more
          exposure, allowing for new players to find your online casino and
          benefit from offers of your choice.
        </p>

        <Link to="/get-listed">
          <div id="list-button">Get published now!</div>
        </Link>
      </div>
    </div>
  );
}
