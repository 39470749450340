import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import SuccessPageImage from '../../images/successPageImage.png'


const ConfirmationPage = () => {
  const history = useHistory()
  return (
    <div className={styles.mainPage}>
      <div className={styles.success}>
        <div className={styles.imageWrapper}>
          <img src={SuccessPageImage} alt="Success image"/>
        </div>
        <div className={styles.textContainer}>
          <div className={classNames(styles.align, styles.green)}>Success!</div>
          <div className={classNames(styles.align, styles.mainText)}>Your application has been submitted.</div>
          <div className={classNames(styles.align, styles.mainText)}>We’ll be in touch soon.</div>
          <button type='button' className={styles.btn} onClick={() => history.push('/get-listed')}>Submit another</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPage
