import React from "react";
import classNames from "classnames";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { VscChromeClose, VscCheck } from "react-icons/vsc";

import "./Step.scss";

export default function Step({
  description,
  maxDirtyStep,
  step,
  current,
  progress,
  onClick,
}) {
  const isActive = step === current;
  const showDirty =
    progress === 0 &&
    (current > step || step <= maxDirtyStep) &&
    step !== current;

  const renderContent = () => {
    if (showDirty) {
      return <VscChromeClose size={15} color="red" />;
    }

    if (progress === 100 && step < 7 && current !== step) {
      return <VscCheck size={15} color="#403488" />;
    }

    return step;
  };

  const renderProgress = () => {
    if (current === step) {
      return null;
    }

    if (progress === 0 || progress === 100) {
      return null;
    }

    return (
      <div style={{ width: 35, height: 35, position: "absolute" }}>
        <CircularProgressbar
          value={progress}
          maxValue={100}
          styles={buildStyles({
            strokeLinecap: "round",
            pathTransitionDuration: 0.5,
            pathColor: "#403488",
            trailColor: "#d6d6d6",
          })}
        />
      </div>
    );
  };

  return (
    <div className="step-wrapper" onClick={onClick}>
      {renderProgress()}
      <div
        className={classNames({
          circle: true,
          activeCircle: isActive,
          filledCircle: progress === 100 && current > step,
          emptyCircle: showDirty,
        })}
      >
        {renderContent()}
      </div>
      <div
        className={classNames({
          content: true,
          activeContent: step === current,
        })}
      >
        {description}
      </div>
      {step < 7 && <div className="tail"></div>}
    </div>
  );
}
