import React from "react";

const TaxonomyPreview = ({ taxonomies, setCurrent, step, message }) => {
  const keys = Object.keys(taxonomies || {});

  return (
    <div style={{ display: "inline" }}>
      {keys.length > 0 ? (
        keys?.map?.(function (id) {
          return <span key={id}>{taxonomies?.[id]?.name}, </span>;
        })
      ) : (
        <span className="errMsg NA" onClick={() => setCurrent(step)}>
          {message} - Click to go back and fix that!
        </span>
      )}
    </div>
  );
};

export default TaxonomyPreview;
