export const topSoftwareProviderNames = [
  "NetEnt",
  "Pragmatic Play",
  "Quickspin",
  "Microgaming",
  "Big Time Gaming",
  "Play'n GO",
  "Thunderkick",
  "Betsoft",
  "1x2 Gaming",
  "Playson",
  "Red Tiger Gaming",
  "iSoftBet",
  "Nolimit City",
  "Evolution Gaming",
  "Yggdrasil Gaming",
  "Playtech",
  "Wazdan",
  "Relax Gaming",
  "NextGen Gaming",
  "Push Gaming",
  "Just For The Win",
  "Booming Games",
  "Lightning Box",
  "Tom Horn Gaming",
  "Elk Studios",
  "Endorphina",
  "Habanero",
  "2 By 2 Gaming",
  "Rabcat",
];