import React, { useRef, useState } from 'react'
import { useController } from "react-hook-form";
import { FaSearch } from "react-icons/fa";

const OPTION_OTHER_NAME = "Other";

const RadioItemsSelect = ({
  name,
  control,
  items = [],
  hideState = false,
  width,
  taxonomyType,
  overrideStyling,
  singleColumn = false,
  hasOptionOther = false,
  ...props
}) => {
  const { field } = useController({
    name,
    control,
  });

  const [search, setSearch] = useState("");
  const [optionOtherKey, setOptionOtherKey] = useState("");

  const classProps = items.length > 10 && !hideState ? "checkbox-wrapper" : "";
  const widthWrapper = width === "half" ? "50%" : "100%";
  const widthTaxonomy = items.length > 20 ? "50%" : "100%";
  const paddingProps = overrideStyling ? "" : "";

  if (hasOptionOther) items = [...items, {name: OPTION_OTHER_NAME}];

  return (
    <div
      style={{
        width: `${widthWrapper}`,
        paddingTop: `${paddingProps}`,
        paddingBottom: `${paddingProps}`,
      }}
    >
      {!hideState ? (
        <div className="search-container">
          <input
            className="sub-con-input"
            style={{ display: "block" }}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          ></input>
          <FaSearch className="search-icon"></FaSearch>
        </div>
      ) : null}
      <div className={`${classProps}`}>
        <div className={singleColumn ? 'checkbox-group-single-col' : 'checkbox-group'}>
          {!hideState &&
            items.map((item) => {
              if (hasOptionOther && item.name === OPTION_OTHER_NAME) {
                const checked = field.value === optionOtherKey;

                return (
                  <div
                    key={item.name}
                    style={{ width: `${widthTaxonomy}` }}
                    className="checkbox-container"
                  >
                    <label
                      className={`data affiliateTypeCheckbox ${
                        checked ? 'checked' : ''
                      }`}
                    >
                      <input
                        key={item.name === OPTION_OTHER_NAME ? optionOtherKey : item.name}
                        name={name}
                        className="languageCheckbox"
                        checked={checked ?? false}
                        onChange={() => {
                          field.onChange(optionOtherKey)
                        }}
                        type="checkbox"
                      ></input>
                      <span className="radio">
                        <span className="selected"></span>
                      </span>
                      <input
                        type="text"
                        placeholder={OPTION_OTHER_NAME}
                        className="other-input"
                        onChange={(e) => {
                          setOptionOtherKey(e.target.value)
                          field.onChange(e.target.value)
                        }}
                        onFocus={(e) => {
                          setOptionOtherKey(e.target.value)
                          field.onChange(e.target.value)
                        }}
                        style={{
                          opacity: 1,
                          marginLeft: 0,
                        }}
                      />
                    </label>
                  </div>
                );
              } else if (item?.name?.toLowerCase().includes(search.toLowerCase())) {
                const checked = field.value === item.name
                return (
                  <div
                    key={item.name}
                    style={{ width: `${widthTaxonomy}` }}
                    className="checkbox-container"
                  >
                    <label
                      className={`data affiliateTypeCheckbox ${
                        checked ? 'checked' : ''
                      }`}
                    >
                      <input
                        key={item.name}
                        name={name}
                        className="languageCheckbox"
                        checked={checked ?? false}
                        onChange={() => {
                          if (taxonomyType === 'checkbox') {
                            field.onChange(item.name)
                          } else {
                            field.onChange(item.name)
                          }
                        }}
                        type="checkbox"
                      ></input>
                      <span className="radio">
                        <span className="selected"></span>
                      </span>
                      {item.name}
                    </label>
                  </div>
                );
              }
            })}
        </div>
      </div>
      <div style={{ height: "1rem", marginBottom: "20px" }}>
        {!field.value && !hideState && (
          <span className="errMsg">You need to make a choice</span>
        )}
      </div>
    </div>
  );
};

export default RadioItemsSelect;
