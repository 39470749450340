export const topLicenseNames = [
  "Curacao",
  "Malta Gaming Authority",
  "UK Gambling Commission",
  "Swedish Gambling Authority",
  "Gibraltar Regulatory Authority",
  "Danish Gambling Authority",
  "Kahnawake Gaming Commission",
  "Estonian Tax and Customs Board",
  "The Alcohol and Gaming Commission of Ontario",
  "DGOJ Spain",
  "Official National Gaming Office",
  "Czech Republic Gaming Board",
  "Segob",
  "AAMS Italy",
  "Lotteries and Gambling Supervisory Inspection Latvia",
  "Alderney Gambling Control Commission",
  "PAGCOR",
  "Dirección General de Juegos y Sorteos Mexico",
  "Ministry of Interior of the State of Schleswig-Holstein",
]