import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ProviderApplication from "./components/provider-application/ProviderApplication";
import ConfirmationPage from "./components/confirmation-page";

import "./App.scss";

function App() {
  return (
    <Router>
      <main>
        <Header />
        <Switch>
          <Route path="/get-listed">
            <ProviderApplication />
          </Route>
          <Route path="/success">
            <ConfirmationPage />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </main>
    </Router>
  );
}

export default App;
