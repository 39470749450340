import React from "react";

import "./Footer.scss";

export default function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <footer>
      <div className="foot-container">
        <div className="foot-content">
          <img
            alt="OnlineCasinoRank.se"
            src="https://onlinecasinorank.se/assets/onlinecasinorank-se_logo?transformations=f_auto,c_fill,q_auto,fl_progressive"
          />
          <br />
          <span>© onlinecasinorank.org {currentYear}</span>
        </div>
      </div>
    </footer>
  );
}
